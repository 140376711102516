import React, { useState, useMemo, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Select,
  Table,
  Dropdown,
  Menu,
  message,
  Pagination,
  Spin,
  Popconfirm,
  Button,
  Tag,
  Drawer,
  Row,
  Col,
} from "antd";
// import { CloseOutlined } from "@ant-design/icons";
import { CustomModal as Modal } from "../../../../util/CustomModal";
// import { PlusOutlined } from "@ant-design/icons";
import { BsArrowLeftCircle } from "react-icons/bs";
import { BiDevices } from "react-icons/bi";
import axiosJSON from "../../../../util/Api";
import ActionModal from "../../../../modals/Settings/AssestDirectory/ActionModal";
import AssetDetails from "../../../../modals/Settings/AssestDirectory/AssetDetails";
import { AiOutlineCloseSquare } from "react-icons/ai";

const AssetsDirectory = (props) => {
  const [total, setTotal] = useState([]);
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);
  const [filterObject, setFilterObject] = useState({});
  const [actionModel, setActionModel] = useState(false);
  const [cardsChecked, setCardsChecked] = useState(false);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [assetDetails, setAssetDetails] = useState(false);
  const [detailId, setDetailId] = useState(null);

  useEffect(() => {
    getAllUsers();
    getDeviceBrand(localStorage.getItem("token"));
    getAllDeviceTypes();
    getListData(1, 10, null);
    // eslint-disable-next-line
  }, [filterObject]);

  const getAllDeviceTypes = () => {
    setLoading(true);
    axiosJSON
      .get("/api/choice/?type=device_type")
      .then(({ data }) => {
        setDeviceTypeOptions(
          data.data.map((data) => {
            return {
              id: data.id,
              name: data.display_name,
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        message.error("Something went wrong, please reach to support");
        setLoading(false);
      });
  };
  const getDeviceBrand = (key) => {
    axiosJSON
      .get(`api/asset_form/brand/?api_key=${key}`)
      .then(({ data }) => {
        setBrandList(data.data);
      })
      .catch((error) => message.error(error.message));
  };

  const filterApiDataConverter = () => {
    let filters = {};
    if (filterObject.deviceType?.length > 0) {
      filters = {
        ...filters,
        device_type: filterObject.deviceType,
      };
    }
    if (filterObject.brand?.length > 0) {
      filters = {
        ...filters,
        device_brand: filterObject.brand,
      };
    }
    if (filterObject.status) {
      filters = {
        ...filters,
        in_use: filterObject.status === "in_use" ? true : false,
      };
    }
    if (filterObject.condition) {
      filters = {
        ...filters,
        is_working: filterObject.condition === "working" ? true : false,
      };
    }
    if (filterObject.action_status) {
      filters = {
        ...filters,
        action_status:
          filterObject.action_status === "assigned"
            ? "assigned"
            : filterObject.action_status === "unassigned"
            ? "unassigned"
            : null,
      };
    }
    if (filterObject.action_status) {
      filters = {
        ...filters,
        action_status:
          filterObject.actionStatus === "move_to_employee"
            ? "move_to_employee"
            : "return_to_office",
      };
    }
    return filters;
  };

  // const getAllBrands = () => {
  //   setLoading(true);
  //   axiosJSON
  //     .get("/api/choice/?type=device_brand")
  //     .then(({ data }) => {
  //       let obj = {};
  //       let brands = data.data.map((data) => {
  //         obj = {
  //           name: data.display_name,
  //           id: data.id,
  //         };
  //         return obj;
  //       });
  //       setBrandOptions(brands);
  //     })
  //     .catch((error) => {
  //       message(error);
  //       setLoading(false);
  //     });
  // };

  const getListData = (page, pageSize, userId, exports = false) => {
    let filters = filterApiDataConverter();
    setLoading(true);
    let userID = userId;
    if (userId === undefined) {
      userID = "";
    } else {
      if (userId === null) {
        userID = employee ? employee : "";
      }
    }
    axiosJSON
      .get(
        `/api/user_assets/?filter_json=` +
          JSON.stringify(filters) +
          `&` +
          `emp_id=` +
          `${
            userID ? userID : ""
          }&page=${page}&page_size=${pageSize}&export=${exports}`
      )
      .then(({ data }) => {
        if (exports) {
          window.open(data.file_url ? data.file_url : "");
          setLoading(false);
        } else {
          setDataSource(
            data.data.map((data, index) => {
              return {
                id: data.id,
                index: index + 1,
                model_no: data.model,
                type: data.device_type.display_name,
                brand: data.device_brand.display_name,
                status: data.in_use ? "In use" : "Not in use",
                condition: data.is_working ? "Working" : "Not Working",
                employee_name: data.owner ? data.owner : "",
              };
            })
          );
          setTotal(data.count);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error("Something went wrong, please reach to support");
        setLoading(false);
      });
  };

  const columns = [
    {
      header: "id",
      accessor: "id",
      key: "id",
    },
    {
      title: "Sl. No",
      dataIndex: "index",
      key: "index",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              openModal(record.id);
            }}
          >
            {text ? text : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Device Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              openModal(record.id);
            }}
          >
            {text ? text : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Device Model No.",
      dataIndex: "model_no",
      key: "model_no",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              openModal(record.id);
            }}
          >
            {record.type === "Laptop" && text ? text : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Device Brand",
      dataIndex: "brand",
      key: "brand",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              openModal(record.id);
            }}
          >
            {text ? text : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (text === "In use") {
          return (
            <Tag
              color="#E6F7FF"
              style={{ color: "#096DD9", borderColor: "#91D5FF" }}
              onClick={() => {
                openModal(record.id);
              }}
            >
              {text}
            </Tag>
          );
        } else if (text === "Not in use") {
          return (
            <Tag
              color="#FFEAAF"
              style={{ color: "#C27A06", borderColor: "#FCD44D" }}
              onClick={() => {
                openModal(record.id);
              }}
            >
              {text}
            </Tag>
          );
        } else if (text === "New") {
          return (
            <Tag
              color="#3D5799"
              onClick={() => {
                openModal(record.id);
              }}
            >
              {text}
            </Tag>
          );
        } else
          return (
            <span
              onClick={() => {
                openModal(record.id);
              }}
            >
              N/A
            </span>
          );
      },
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      render: (text, record) => {
        if (text === "Working") {
          return (
            <Tag
              color="#EAFEEF"
              style={{ color: "#136927", borderColor: "#28A745" }}
              onClick={() => {
                openModal(record.id);
              }}
            >
              {text}
            </Tag>
          );
        } else if (text === "Not Working") {
          return (
            <Tag
              color="#FFDCDC"
              style={{ color: "#EE3636", borderColor: "#EF4444" }}
              onClick={() => {
                openModal(record.id);
              }}
            >
              {text}
            </Tag>
          );
        } else
          return (
            <div
              onClick={() => {
                openModal(record.id);
              }}
            >
              N/A
            </div>
          );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              openModal(record.id);
            }}
          >
            {text ? text : "N/A"}
          </div>
        );
      },
    },
    {
      title: <span style={{ marginLeft: "15px" }}>Actions</span>,
      dataIndex: "",
      key: "action",
      render: (text, record) => (
        <Dropdown.Button
          overlay={menu(record.id, record.employee_name !== "")}
          trigger={["click"]}
        />
      ),
    },
  ];

  const onOptionMenuItemSelect = (event, id) => {
    if (event.key === "assigned") {
      const newSelectedItem = [...checkedItems];
      newSelectedItem.push(id);
      setCheckedItems(newSelectedItem);
      setActionModel(true);
    }
  };

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setCurrentSize(size);
    getListData(page, size, null);
  };

  const openModal = (id) => {
    setAssetDetails(true);
    setDetailId(id);
  };

  const listView = useMemo(() => {
    return (
      <div className="row backgroud-custom">
        <Table
          columns={columns}
          loading={loading}
          className="assetinfo"
          dataSource={dataSource}
          style={{ width: "100%", cursor: "pointer" }}
          initialState={{ hiddenColumns: ["id"] }}
          pagination={false}
          // onRow={(record) => {
          //   return {
          //     onClick: (event) => {
          //       openModal(record.id);
          //     },
          //   };
          // }}
        />
        {total > 0 ? (
          <div
            className="rightPagination userDirectory-pagination px-2"
            style={{ width: "100%" }}
          >
            <Pagination
              total={total}
              showSizeChanger
              current={currentPage}
              pageSize={currentSize}
              onChange={onPageChange}
              onShowSizeChange={onPageChange}
            />
          </div>
        ) : null}
      </div>
    );
    // eslint-disable-next-line
  }, [dataSource, columns, actionModel, filterObject]);

  const returnToOfficeAPI = (id = null) => {
    setLoading(true);
    if (id !== null) {
      const asset_ids = [id];
      axiosJSON
        .put("/api/user_assets/return/", { asset_ids: asset_ids })
        .then((res) => {
          message.success("Updated data successfully");
          setCardsChecked(false);
          getListData(currentPage, currentSize, null);
        })
        .catch((error) => {
          message.error("Something went wrong");
          setLoading(false);
        });
    }
  };

  const menu = (id, isReturnedAllready) => (
    <Menu onClick={(event) => onOptionMenuItemSelect(event, id)}>
      <Menu.Item key="assigned">
        <span>Move to Employee</span>
      </Menu.Item>
      {isReturnedAllready ? (
        <Menu.Item key="unassigned">
          <Popconfirm
            placement="topLeft"
            title={"Are you sure to unassign?"}
            description={"description"}
            onConfirm={() => returnToOfficeAPI(id)}
            okText="Yes"
            cancelText="No"
          >
            <span>Return to Office</span>
          </Popconfirm>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const onCancel = () => {
    setActionModel(false);
    setAssetDetails(false);
    setEdit(false);
    setSave(false);
    getListData(currentPage, currentSize, null);
  };

  const onComplete = () => {
    setActionModel(false);
    setCheckedItems([]);
    setCardsChecked(false);
    getListData(currentPage, currentSize, null);
  };

  const getAllUsers = () => {
    axiosJSON
      .get("/api/user/")
      .then(({ data }) => {
        let obj = {};
        let users = [];
        data.data.map((user, index) => {
          obj = {
            employee_id: user.employee_id,
            id: user.id,
            name: user.name,
          };
          users.push(obj);
          return null;
        });
        setUserList(users);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="col-12 pt-4">
          <div className="row m-0 headDir space-bet mb-3 pb-4">
            <div className="row m-0 back2">
              <BsArrowLeftCircle
                className="back-btn mr-4"
                onClick={() => {
                  props.history.push("/settings");
                  props.changeSawDrawer(true);
                }}
              />
              <div className="assetsdr">
                <BiDevices className="mr-2" />
                <div className="assert-head">Assets Directory</div>
              </div>
            </div>
            <div className="pr-2 ml-4">
              {/* <Button
                type="primary"
                onClick={() =>
                  props.history.push({
                    pathname: "/assetinfo",
                  })
                }
              >
                <IoMdAdd style={{ marginRight: "4px" }} /> Add Device
              </Button> */}
            </div>
          </div>
          <div className="row m-0 seprate-dir">
            <div className="pr-2"></div>

            <div className="row mb-3">
              {cardsChecked ? (
                <>
                  <div className="pr-3">
                    <Select
                      className="wid-150"
                      onChange={(value) => {
                        if (value === "assigned") {
                          setActionModel(true);
                        }
                        getListData(1, 10);
                      }}
                      allowClear
                      placeholder="Status"
                      value={filterObject.status}
                    >
                      <Select.Option key="1" value={"assigned"}>
                        Assign
                      </Select.Option>
                      <Select.Option key="2" value={"unassigned"}>
                        <Popconfirm
                          placement="topLeft"
                          title={"Are you sure to unassign?"}
                          description={"description"}
                          onConfirm={() => returnToOfficeAPI()}
                          okText="Yes"
                          cancelText="No"
                        >
                          Unassign
                        </Popconfirm>
                      </Select.Option>
                    </Select>
                  </div>
                </>
              ) : null}
              <div className="pr-3">
                <Button
                  onClick={() =>
                    getListData(currentPage, currentSize, null, true)
                  }
                >
                  {" "}
                  Export{" "}
                </Button>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        deviceType: value,
                      };
                    });
                    getListData(1, 10);
                  }}
                  placeholder="Device Type"
                  showArrow="true"
                  allowClear
                  mode="multiple"
                  value={filterObject.deviceType}
                >
                  {deviceTypeOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        brand: value,
                      };
                    });
                  }}
                  placeholder="Device Brand"
                  allowClear
                  mode="multiple"
                  showArrow="true"
                  value={filterObject.brand}
                >
                  {brandList.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.display_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        status: value,
                      };
                    });
                  }}
                  allowClear
                  placeholder="Device Status"
                  value={filterObject.status}
                >
                  <Select.Option key="1" value={"in_use"}>
                    In use
                  </Select.Option>
                  <Select.Option key="2" value={"not_in_use"}>
                    Not use
                  </Select.Option>
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        action_status: value,
                      };
                    });
                  }}
                  allowClear
                  placeholder="Action Status"
                  value={filterObject.action_status}
                >
                  <Select.Option key="1" value="unassigned">
                    Unassigned
                  </Select.Option>
                  <Select.Option key="2" value="assigned">
                    Assigned
                  </Select.Option>
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  className="wid-150"
                  onChange={(value) => {
                    setFilterObject((preState) => {
                      return {
                        ...preState,
                        condition: value,
                      };
                    });
                  }}
                  allowClear
                  placeholder="Condition"
                  value={filterObject.condition}
                >
                  <Select.Option key="1" value="working">
                    Working
                  </Select.Option>
                  <Select.Option key="2" value="not_working">
                    Not Working
                  </Select.Option>
                </Select>
              </div>
              <div className="pr-3">
                <Select
                  showSearch
                  allowClear={true}
                  notFoundContent={null}
                  value={employee}
                  placeholder="Search Employee"
                  style={{ width: "200px" }}
                  onChange={(userId) => {
                    setEmployee(userId);
                    getListData(1, 10, userId);
                    setCurrentPage(1);
                    setCurrentSize(10);
                  }}
                  optionFilterProp="children"
                >
                  {userList.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          {listView}
          {actionModel ? (
            <Modal
              title="Move to Employee"
              visible={actionModel}
              footer={null}
              onCancel={() => onCancel()}
            >
              <ActionModal
                closeModal={onCancel}
                onComplete={onComplete}
                selectedItems={checkedItems}
                userList={userList}
                props={props}
              />
            </Modal>
          ) : null}
          <div>
            {assetDetails && (
              <Drawer
                onClose={onCancel}
                width="55vw"
                title={
                  <>
                    <AiOutlineCloseSquare
                      onClick={onCancel}
                      style={{
                        height: "20px",
                        width: "20px",
                        fontSize: "24px",
                        cursor: "pointer",
                        float: "left",
                        zIndex: 1,
                        pointerEvents: "auto",
                      }}
                    />
                    <Row className="Create_new_drawer">
                      <Col
                        span={12}
                        className="column-with-line"
                      >
                        <div
                          style={{
                            alignItems: "center",
                            color: "#000000",
                            fontSize: "20px",
                          }}
                        >
                          <span>Asset Details</span>
                          {!edit ? (
                            <Button
                              onClick={() => setEdit(true)}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                width: "47px",
                                height: "29px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#26305C",
                                borderColor: "#26305C",
                                backgroundColor: "#ECEEF1",
                                fontSize: "14px",
                                marginRight: "10px",
                              }}
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              onClick={() => setSave(true)}
                              style={{
                                cursor: "pointer",
                                float: "right",
                                width: "158px",
                                height: "29px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "14px",
                                marginRight: "10px",
                              }}
                            >
                              {" "}
                              Save the Changes{" "}
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#000000",
                            marginLeft: "12px",
                          }}
                        >
                          {" "}
                          Activity
                          <br />
                          <span
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "12px",
                              color: "#2A2B2C",
                              fontSize: "12px",
                            }}
                          >
                            The history of activity performed on the device.
                          </span>{" "}
                        </div>
                      </Col>
                    </Row>
                  </>
                }
                open={assetDetails}
                destroyOnClose={true}
                closable={false}
                maskClosable={true}
              >
                <AssetDetails
                  edit={edit}
                  save={save}
                  setSave={setSave}
                  handleClose={onCancel}
                  dataSource={dataSource}
                  detailId={detailId}
                />
              </Drawer>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
};

export default withRouter(AssetsDirectory);

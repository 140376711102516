import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNOUT_USER,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  ACCESS_TOKEN,
  USER,
  NEW_USER,
  USER_ACCESS,
  IMPERSONATE,
} from "constants/ActionTypes";

import axiosJSON from "../../util/Api";


export const userSignIn = ({ employeeId, password }, fcm_token) => {
  const body = {
    employee_id: employeeId,
    password: password,
    fcm_token: fcm_token,
  };
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axiosJSON
      .post("/api/auth/login/", body)
      .then(({ data, status }) => {
        if (status === 200) {
          localStorage.setItem("token", data.data.token);
          let obj = JSON.stringify({ query: "", filter_for: "" });
          localStorage.setItem("filter", obj);
          localStorage.setItem("filterObj", "{}");
          axiosJSON.defaults.headers["authorization"] =
            "Token " + data.data.token;
          localStorage.setItem("beatsToken",data.data.token)
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: ACCESS_TOKEN, payload: data.data.token });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const myDetails = (token) => {
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .get("/api/user/me/")
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const impersonatedMeDetails = (token) => {
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON.defaults.headers["authorization"] = "Token " + token;
    axiosJSON
      .get("/api/user/me/")
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          let current_token = localStorage.getItem("current_token");
          if (current_token) {
            axiosJSON.defaults.headers["authorization"] = "Token " + token;
            dispatch({ type: NEW_USER, payload: data.data });
            axiosJSON.defaults.headers["authorization"] = "Token " + token;
            dispatch({ type: IMPERSONATE, payload: true });
            axiosJSON.defaults.headers["authorization"] = "Token " + token;
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const stopImpersonate = () => {
  localStorage.removeItem("current_token");
  return (dispatch) => {
    dispatch({ type: NEW_USER, payload: "" });
    dispatch({ type: IMPERSONATE, payload: false });
    window.location.reload();
  };
};

export const userSignOut = (history) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .delete("/api/user/logout/")
      .then(({ data, status }) => {
        localStorage.clear();
        dispatch({ type: NEW_USER, payload: "" });
        dispatch({ type: IMPERSONATE, payload: false });
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER });
        history.push("/signin");
      })
      .catch(function (error) {});
  };
};

export const getUserAccess = (token) => {
  axiosJSON.defaults.headers["authorization"] = "Token " + token;
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axiosJSON
      .get("/api/user/access/")
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_ACCESS, payload: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

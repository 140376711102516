import React from "react";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter } from "react-router-dom";
import "assets/vendors/style";
import "./styles/beats_main.less";
import configureStore, { history } from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/index";
import 'typeface-inter';

const store = configureStore(/* provide initial state if any */);

const NextApp = () => {
  return (
    <Provider store={store}>
      <HashRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </HashRouter>
    </Provider>
  );
};

export default NextApp;

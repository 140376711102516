import axios from "axios";
import { message } from "antd";

export const BASE_URL = "https://api.gobeats.app"; //beats-prod
// export const BASE_URL = "https://hr.gobeats.app"; //india-hr-prod
  //  export const BASE_URL = "https://staging.gobeats.app"; //beats-staging
//export const BASE_URL = "https://indstaging.gobeats.app"; //beats-india-staging
// export const BASE_URL = "https://dev.gobeats.app"; //beats-dev

// export const DASHBOARD_URL = "http://localhost:8000/add"; //local
export const DASHBOARD_URL = "https://bta-prod.log1.com/add"//prod
// export const DASHBOARD_URL = "https://bta.log1.com/add"; //staging


// export const LOG1_API_KEY = "jOeQPscC.afkRK8KqVjTUGIeYRED73jGZ7DRfAOnw"; //dev
// export const LOG1_API_KEY = "jOeQPscC.afkRK8KqVjTUGIeYRED73jGZ7DRfAOnw"; //staging
export const LOG1_API_KEY = "jOeQPscC.afkRK8KqVjTUGIeYRED73jGZ7DRfAOnw"; //prod



export const LOG1_BASE_URL = "https://api.log1.com/"; //prod
// export const LOG1_BASE_URL = "https://staging.log1.com/"; //staging
// export const LOG1_BASE_URL = "http://65.0.105.55:8000/"; //dev


export let axiosJSON;

axiosJSON = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

axiosJSON.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      delete axiosJSON.defaults.headers["authorization"];
      localStorage.clear();
      window.location.reload();
    } else if (
      error.response.status >= 400 &&
      error.response.status !== 401 &&
      error.response.status < 500
    ) {
      return Promise.reject(error.response.data);
    } else if (error.response.status === 500) {
      message.error("Server error");
    }
  }
);

export default axiosJSON;

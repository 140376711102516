import React, { Component } from "react";
import { Dropdown, Menu, message, Pagination, Table } from "antd";
import axiosJSON from "../../../util/SetHeaderAPI";
import EditModule from "../../../modals/Training/editModule";
import TrainingFeedback from "../../../modals/Training/trainingFeedback";
import Assignment from "../../../modals/Training/assignment";
import CircularProgress from "../../../components/CircularProgress";
import CandidateDetails from "../../../modals/Training/candidateDetails";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router";
import "nprogress/nprogress.css";
import nprogress from "nprogress";
import { CustomModal as Modal, confirm } from "../../../util/CustomModal";
import EditSessionType from "../../../modals/Training/editSessionType";
import { FiEye, FiPlusCircle } from "react-icons/fi";
import { HiOutlineMinusSm } from "react-icons/hi";
import Reason from "../../../modals/Training/reason";
import AddGithubLink from "../../../modals/Training/addGithubLink";

class TrainingDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidateList: [],
      editModule: false,
      editSessionType: false,
      viewCandidate: false,
      reasonModal: false,
      record: null,
      trainingFeedback: false,
      assigmentSubmit: false,
      loader: false,
      page: 1,
      total: 0,
      trainingId: this.props.history.location.pathname.split("/")[2],
      openLink: false,
      editLink: false,
    };
    this.Mounted = false;
  }

  componentDidMount() {
    nprogress.start();
    this.Mounted = true;
    this.getTrainingCandidateList(this.state.page);
    nprogress.done();
  }

  getTrainingCandidateList(page) {
    this.setState({ loader: true });
    let status = this.props.subFilterObj.hasOwnProperty("status")
      ? this.props.subFilterObj.status
      : "";
    let sessionType = this.props.subFilterObj.hasOwnProperty("session_type")
      ? this.props.subFilterObj.session_type
      : "";
    let language = this.props.subFilterObj.hasOwnProperty("language")
      ? this.props.subFilterObj.language
      : "";
    axiosJSON
      .get(
        "/api/training_candidate/?training_id=" +
          this.state.trainingId +
          "&query=" +
          this.props.subQuery +
          "&status=" +
          status +
          "&language=" +
          language +
          "&session_type=" +
          sessionType.toLowerCase() +
          "&page=" +
          page
      )
      .then((res) => {
        let candidateList = [];
        res.data.data.map((data) => {
          let obj = {
            id: data.candidate.id,
            name: data.candidate.name,
            email: data.candidate.email,
            recruiter: data.candidate.recruiter
              ? data.candidate.recruiter
              : "N/A",
            technology: data.technology,
            module: data.module ? data.module : "-",
            session_type: data.session_type ? data.session_type : "-",
            status: data.status ? data.status : "-",
            reason: data.reason ? data.reason : "",
            enrollment_date: moment(data.enrollment_date).format(
              "MMM DD, YYYY"
            ),
            training_candidate_id: data.id,
            link: data.link ? data.link : null,
          };
          candidateList.push(obj);
          return null;
        });
        localStorage.setItem(
          "training_candidate_count",
          JSON.stringify(res.data.counts)
        );
        candidateList.map((item) => item.link !== null);
        this.setState({
          candidateList: candidateList,
          total: res.data.total,
          loader: false,
        });
        this.props.populateData();
      })
      .catch(function (error) {
        message.error(error.message);
      });
  }

  componentWillUnmount() {
    this.Mounted = false;
  }

  editModule = (record) => {
    // axiosJSON
    //   .get(
    //     "api/training_candidate/" + record.training_candidate_id + "/module"
    //   )
    //   .then((res) => {
    this.setState({
      editModule: true,
      record: record,
    });
    // })
    // .catch(function (error) {
    //   message.error(error.message);
    // });
  };
  editSessionType = (record) => {
    this.setState({
      editSessionType: true,
      record: record,
    });
  };

  viewCandidateDetails = (record) => {
    this.props.history.push({
      pathname: `/trainingDetails/${record.training_candidate_id}/${record.id}/assignment`,
      state: { path: this.props.history.location.pathname },
    });
  };

  getFeedback = (record) => {
    this.setState({
      trainingFeedback: true,
      record: record,
    });
  };

  setSubmissionDate = (record) => {
    this.setState({
      assigmentSubmit: true,
      record: record,
    });
  };
  showLink = (record, check) => {
    if (check === "add") {
      this.setState({
        openLink: true,
        record: record,
      });
    } else {
      this.setState({
        editLink: true,
        record: record,
      });
    }
  };
  handleClose = () => {
    this.setState(
      {
        editModule: false,
        editSessionType: false,
      },
      () => this.getTrainingCandidateList(this.state.page)
    );
  };

  handleModalClose = () => {
    this.setState({
      trainingFeedback: false,
      assigmentSubmit: false,
      viewCandidate: false,
      editModule: false,
      editSessionType: false,
      reasonModal: false,
      openLink: false,
      editLink: false,
    });
  };

  deletetModule = (record) => {
    confirm({
      title:
        "Do you want to delete " + record?.module?.title + " for " + record.name + " ?",
      // content: 'When clicked the "Yes, Delete the Module" button, module ' + record.module +' will be deleted. Are you sure you want to delete this module?',
      okText: "Yes, Delete the Module",
      cancelText: "Cancel",
      onOk: () => {
        axiosJSON
          .delete(
            "/api/training_candidate/" + record.training_candidate_id + "/module/"
          )
          .then(({ data, status }) => {
            message.success("Module Deleted");
            this.getTrainingCandidateList(this.state.page);
          })
          .catch(function (error) {
            message.error(error.message);
          });
      },
    });
  };

  changeStatus = (record, status) => {
    confirm({
      title: `Do you want to change the status of candidate '${record.name}' to '${status}' ?`,
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        let body = {
          status: status,
        };
        axiosJSON
          .put(
            "api/training_candidate/" + record.training_candidate_id + "/",
            body
          )
          .then(({ data, status }) => {
            if (status === 202) {
              message.success(data.message);
              this.getTrainingCandidateList(this.state.page);
            }
          })
          .catch(function (error) {
            message.error(error.message);
          });
      },
    });
  };

  menu = (record) => {
    return (
      <Menu>
        <Menu.Item
          key="edit"
          onClick={(e) => {
            e.domEvent.stopPropagation();
            this.editModule(record);
          }}
        >
          <span>Edit Module</span>
        </Menu.Item>
        <Menu.Item
          key="session_type"
          onClick={(e) => {
            this.editSessionType(record);
          }}
        >
          <span>Edit Session Type</span>
        </Menu.Item>
        {record.module !== "-" && (
          <Menu.Item key="delete" onClick={() => this.deletetModule(record)}>
            <span>Delete Module</span>
          </Menu.Item>
        )}
        <Menu.Item
          key="training_feedback"
          onClick={() => this.getFeedback(record)}
        >
          <span>Add Training Feedback</span>
        </Menu.Item>
        <Menu.Item
          key="assignment"
          onClick={() => this.setSubmissionDate(record)}
        >
          <span>Assignment Submit</span>
        </Menu.Item>
        {!record.link ? (
          <Menu.Item
            key="add_github_link"
            onClick={() => this.showLink(record, "add")}
          >
            <span>Add Github Link</span>
          </Menu.Item>
        ) : (
          <Menu.Item
            key="edit_github_link"
            onClick={() => this.showLink(record, "edit")}
          >
            <span>Edit Github Link</span>
          </Menu.Item>
        )}
        {record.status === "Confirmed" ? (
          <Menu.Item
            key="change_status"
            onClick={() => this.changeStatus(record, "Removed")}
          >
            <span>Remove Candidate</span>
          </Menu.Item>
        ) : (
          <Menu.Item
            key="change_status"
            onClick={() => this.changeStatus(record, "Confirmed")}
          >
            <span>Change Status</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  columns = [
    {
      title: "Consultant Name",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "1",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Recruiter",
      dataIndex: "recruiter",
      key: "3",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Technology",
      dataIndex: "technology",
      key: "4",
      render: (record, data) => {
        return <div>{record ? record : "N/A"}</div>;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Module",
      dataIndex: "module",
      key: "5",
      render: (record, data) => {
        return <div>{record.title ? record.title : "N/A"}</div>;
      },
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Enrollment Date",
      dataIndex: "enrollment_date",
      key: "6",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Session Type",
      dataIndex: "session_type",
      key: "7",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.viewCandidateDetails(record);
        },
      }),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "8",
      onCell: (record, rowIndex) => ({
        onClick: () => {
          this.setState({ record: record });
        },
      }),
      render: (text, record) => {
        return record.session_type === "Recorded" ? (
          record.reason === "" ? (
            <FiPlusCircle
              style={{ backgroundColor: "#FFF8EA", borderRadius: "34px" }}
              className="icon-reason-modal"
              onClick={() => {
                this.setState({
                  editSessionType: true,
                });
              }}
            />
          ) : (
            <FiEye
              className="icon-reason-modal"
              onClick={() => {
                this.setState({
                  reasonModal: true,
                  record: record,
                });
              }}
            />
          )
        ) : (
          <HiOutlineMinusSm className="icon-reason-modal" />
        );
      },
    },
    {
      key: "operation",
      fixed: "right",
      render: (text, record) => (
        <div className="drop_correction">
          <Dropdown.Button
            overlay={() => this.menu(record)}
            trigger={["click"]}
          />
        </div>
      ),
    },
  ];

  render() {
    const {
      editModule,
      trainingFeedback,
      assigmentSubmit,
      loader,
      viewCandidate,
    } = this.state;
    return (
      <div className="gx-main-content custom_top attend_style">
        <div className="gx-module-box-topbar gx-module-box-topbar-todo hide_dis">
          <div className="sort_drop col-2"></div>
        </div>
        {loader ? (
          <CircularProgress />
        ) : (
          <Table
            className="candidate_table"
            rowKey={(record) => record.id}
            columns={this.columns}
            dataSource={this.state.candidateList}
            pagination={false}
          />
        )}
        {this.state.candidateList.length > 0 && (
          <div className="Pagination-section">
            <Pagination
              current={this.state.page}
              total={this.state.total}
              pageSize={10}
              onChange={(page) =>
                this.setState({ page: page }, () =>
                  this.getTrainingCandidateList(page)
                )
              }
            />
          </div>
        )}
        {editModule && (
          <Modal
            title="Module"
            visible={editModule}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <EditModule
              candidate={this.state.record}
              moduleValue={this.state.moduleValue}
              closeModal={this.handleClose}
            />
          </Modal>
        )}
        {this.state.editSessionType && (
          <Modal
            title="Update Session Type"
            visible={this.state.editSessionType}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <EditSessionType
              candidate={this.state.record}
              closeModal={this.handleClose}
            />
          </Modal>
        )}
        {this.state.reasonModal && (
          <Modal
            title="Reason"
            visible={this.state.reasonModal}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <Reason candidate={this.state.record} />
          </Modal>
        )}
        {trainingFeedback && (
          <Modal
            title="Feedback"
            visible={trainingFeedback}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <TrainingFeedback
              candidate={this.state.record}
              closeModal={this.handleModalClose}
            />
          </Modal>
        )}

        {assigmentSubmit && (
          <Modal
            title="Assignment"
            visible={assigmentSubmit}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <Assignment
              candidate={this.state.record}
              closeModal={this.handleModalClose}
              assignmentObj={false}
              edit={false}
            />
          </Modal>
        )}
        {viewCandidate && (
          <Modal
            title="Candidate Details"
            className="candidate-details mod-w"
            visible={viewCandidate}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <CandidateDetails
              candidate={this.state.record}
              training_id={this.state.trainingId}
              closeModal={() => this.handleModalClose()}
            />
          </Modal>
        )}
        {this.state.openLink && (
          <Modal
            title="Add Github Link"
            visible={this.state.openLink}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <AddGithubLink
              onComplete={() => this.getTrainingCandidateList(this.state.page)}
              candidate={this.state.record}
              training_id={this.state.trainingId}
              closeModal={() => this.handleModalClose()}
            />
          </Modal>
        )}
        {this.state.editLink && (
          <Modal
            title="Edit Github Link"
            visible={this.state.editLink}
            footer={null}
            onCancel={() => this.handleModalClose()}
          >
            <AddGithubLink
              onComplete={() => this.getTrainingCandidateList(this.state.page)}
              candidate={this.state.record}
              training_id={this.state.trainingId}
              closeModal={() => this.handleModalClose()}
            />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subFilterObj: state.common.sub_filter_obj,
  subQuery: state.common.sub_query,
});

const routeTrainingDetailList = withRouter(TrainingDetailList);

export default connect(mapStateToProps)(routeTrainingDetailList);

import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Spin,
  Input,
  Select,
  DatePicker,
  Radio,
  message,
  Divider,
  Tag,
  Row,
  Col,
  Upload,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axiosJSON from "../../../util/Api";
import { AiOutlineCheckCircle } from "react-icons/ai";
import moment from "moment";
import { BiHistory, BiUpload } from "react-icons/bi";
import { CloseCircleOutlined } from "@ant-design/icons";

const AssetDetails = (props) => {
  const { detailId, edit, save } = props;
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [name, setName] = useState(null);
  const [assignedDate, setAssignedDate] = useState(null);
  const [bulkFile, setBulkFile] = useState([]);
  const [serialImage, setSerialImage] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [modelNo, setModelNo] = useState(null);
  const [assetType, setAssetType] = useState(null);
  const [condition, setCondition] = useState(null);
  const [status, setStatus] = useState(null);
  const [ownerName, setOwnerName] = useState(null);
  const [ownerId, setOwnerId] = useState(null);
  const [attachmentId, setAttachmentId] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [activity, setActivity] = useState([]);
  const [disableInUse, setDisableInUse] = useState(false);
  const [disableSerial, setDisableSerial] = useState(false);
  const [fileCheck, setFileCheck] = useState(false);
  const [serialAttachments, setSerialAttachments] = useState([]);

  useEffect(() => {
    getDeviceBrand(localStorage.getItem("token"));
    getDeviceType(localStorage.getItem("token"));
    getAssetDetailsData(detailId);
    getActivity(detailId);
    if (save) {
      saveChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  const handleFileChange = (info, type) => {
    if (type === "image") {
      let newFileList = [...info.fileList];

      newFileList = newFileList.slice(-2);

      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      // console.log(newFileList);
      setBulkFile(newFileList);
      setFileCheck(true);
    } else {
      setSerialImage(info[0]);
      setFileCheck(true);
    }
  };
  const handleFileRemove = (fileId, type) => {
    if (type === "serial") {
      setSerialImage((file) => {
        file.filter((files) => files.id !== fileId);
      });
    } else {
      setBulkFile((bulkFiles) => {
        bulkFiles.filter((file) => file.id !== fileId);
      });
    }
    // console.log(
    //   "removed bulk files",
    //   bulkFile.filter((file) => file.id === fileId)
    // );
  };

  const getAssetDetailsData = (id) => {
    axiosJSON
      .get("api/user_assets/" + id + "/")
      .then(({ data }) => {
        setFilterData(data.data);
        if (data.data.users.length > 0) {
          data.data.users.map((item) => {
            if (item.is_current === true) {
              setOwnerName(item.name);
              setOwnerId(item.employee_id);
            }
            return item;
          });
        }
        if (data.data.attachments.length > 0) {
          setFileCheck(true);
          setAttachmentId(data.data.attachments.map((item) => item.id));
          let asset = [];
          let serialAsset = [];
          data.data.attachments.filter((image) => {
            if (image.attachment_type === "asset") {
              return asset.push(image);
            } else return serialAsset.push(image);
          });
          setAttachment(asset);
          setSerialAttachments(serialAsset);
        }
      })
      .catch((error) => message.error(error.message));
  };

  const getDeviceBrand = (key) => {
    axiosJSON
      .get(`api/asset_form/brand/?api_key=${key}`)
      .then(({ data }) => {
        setBrandList(data.data);
      })
      .catch((error) => message.error(error.message));
  };
  const getDeviceType = (key) => {
    axiosJSON
      .get(`api/asset_form/type/?api_key=${key}`)
      .then(({ data }) => {
        setDeviceList(data.data);
      })
      .catch((error) => message.error(error.message));
  };
  const getActivity = (id) => {
    axiosJSON
      .get("api/user_assets/" + id + "/activity/")
      .then(({ data }) => {
        setActivity(data.data);
      })
      .catch((error) => message.error(error.message));
  };

  const saveChanges = () => {
    const formData = new FormData();
    let owner = null;
    owner = ownerId ? ownerId.toString() : null;
    let type = null;
    type = assetType ? assetType : filterData.type?.name;
    let model = null;
    model = modelNo
      ? modelNo
      : !attachmentId.length > 0
      ? ""
      : assetType === "laptop"
      ? filterData.model
      : "";
    let brands = [];
    brands = brand
      ? brand
      : {
          value: filterData.brand?.name,
          label: filterData.brand?.display_name,
        };
    let Inuse =
      status === "in_use"
        ? true
        : status === "not_in_use"
        ? false
        : filterData.in_use;
    let Isworking =
      condition === "working"
        ? true
        : condition === "not_working"
        ? false
        : filterData.is_working;
    let modelImage = serialImage ? "model_image_0" : null;
    let modelImage_0 = [];
    modelImage_0 = serialImage ? serialImage : null;
    // let images = bulkFile ? { key: "device_0" } : null;
    let assignDate = assignedDate
      ? moment(assignedDate).format("YYYY-MM-DD")
      : filterData.assigned_date;
    if (
      (filterData.type?.name === "laptop" && !filterData.model && !modelNo) ||
      (assetType === "laptop" && !modelNo && !filterData.model?.length > 0)
    ) {
      message.error("Please Enter Serial Number.");
      props.setSave(false);
    } else if (
      !fileCheck ||
      (!filterData.attachments?.length > 0 && !bulkFile?.length > 0)
    ) {
      message.error("Please upload Device images.");
      props.setSave(false);
    } else if (filterData.type?.name === "laptop" && !serialImage?.length > 0) {
      message.error("Please upload serial image.");
      props.setSave(false);
    } else {
      formData.append("attachment_ids", [attachmentId]);
      formData.append("ownder_id", owner);
      formData.append("device_type", type);
      formData.append("model", model);
      formData.append("device_brand", JSON.stringify(brands));
      formData.append("in_use", Inuse);
      formData.append("is_working", Isworking);
      formData.append("model_image", modelImage);
      if (modelImage.length > 0) {
        formData.append("model_image_0", modelImage_0);
      }

      if (bulkFile && bulkFile.length > 0) {
        for (let index = 0; index < bulkFile.length; index++) {
          let assetKey = "device_" + index;
          const file = bulkFile[index];
          // console.log(index, assetKey, file);
          if (file !== undefined) {
            formData.append("image", assetKey);
            formData.append(assetKey, file);
          }
        }
      }
      formData.append("assigned_date", assignDate);
      axiosJSON
        .put("api/user_assets/" + detailId + "/", formData)
        .then(({ data }) => {
          setLoading(false);
          message.success("Data saved successfully");
          props.handleClose();
        })
        .catch((error) => {
          message.error(error.message);
          props.setSave(false);
        });
    }
  };

  const addNewBrand = (e) => {
    e.preventDefault();
    let keyName = name
      .split(" ")
      .map((x) => x.toLowerCase())
      .join("_");
    if (keyName) {
      if (
        brandList.filter(
          (item) =>
            item.name === keyName ||
            item.display_name.toLowerCase() === name.toLowerCase()
        ).length > 0
      ) {
        message.error("Brand already Present");
      } else {
        setBrandList([...brandList, { name: keyName, display_name: name }]);
        setName("");
      }
    } else message.error("Please enter Name");
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  return (
    <Row className="Create_new_drawer">
      <Col span={12} className="column-with-line">
        <div>
          <Spin
            spinning={loading}
            tip="Please wait...Your data is in progress.."
          >
            <div className="case-drawer">
              <label
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  color: "#000000",
                  fontSize: "16px",
                }}
              >
                Asset Info{"  "}
                {filterData.action_status === "unassigned" ? (
                  <Tag
                    color="#C2D3FF"
                    style={{
                      color: "#3D5799",
                      borderColor: "#5274CC",
                      marginLeft: "5px",
                    }}
                  >
                    Unassigned
                  </Tag>
                ) : filterData.action_status === "unassigned" ? (
                  <Tag
                    color="#C4E3E7"
                    style={{
                      color: "#56939B",
                      borderColor: "#6BB8C2",
                      marginLeft: "5px",
                    }}
                  >
                    Assigned
                  </Tag>
                ) : null}
              </label>
            </div>

            <div className="asset-drawer-wrapper">
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Asset Type </span>
                {!edit ? (
                  <span style={{ float: "right", color: "#2A2B2C" }}>
                    {filterData.type?.display_name}
                  </span>
                ) : (
                  <Select
                    placeholder="Device Type"
                    style={{
                      width: "50%",
                      float: "right",
                      marginBottom: "2px",
                    }}
                    defaultValue={filterData.type?.display_name}
                    onChange={(event) => {
                      setAssetType(event);
                      if (event === "laptop") {
                        setDisableSerial(false);
                      } else {
                        setDisableSerial(true);
                      }
                    }}
                  >
                    {deviceList.map((device, i) => (
                      <Select.Option value={device.name} key={i}>
                        {device.display_name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </div>
              {(filterData.type?.display_name === "Laptop" && !disableSerial) ||
              (filterData.type?.display_name !== "Laptop" &&
                assetType === "laptop") ? (
                <div className="case-drawer">
                  {edit ? (
                    <span style={{ color: "red", fontSize: 9 }}>*</span>
                  ) : null}
                  <span style={{ color: "#535558" }}> Serial Number </span>
                  {!edit ? (
                    <span style={{ float: "right", color: "#2A2B2C" }}>
                      {filterData.model ? filterData.model : "Not Available"}
                    </span>
                  ) : (
                    <Input
                      style={{
                        width: "50%",
                        float: "right",
                        marginBottom: "2px",
                      }}
                      placeholder="Serial No."
                      defaultValue={filterData.model}
                      onChange={(event) => setModelNo(event.target.value)}
                    />
                  )}
                </div>
              ) : null}
              <div className="case-drawer">
                {edit ? (
                  <span
                    style={{ color: "red", fontSize: 9, marginRight: "4px" }}
                  >
                    *
                  </span>
                ) : null}
                <span style={{ color: "#535558" }}>Asset Brand </span>
                {!edit ? (
                  <span style={{ float: "right", color: "#2A2B2C" }}>
                    {filterData.brand?.display_name
                      ? filterData.brand?.display_name
                      : "Not Available"}
                  </span>
                ) : (
                  <Select
                    style={{
                      width: "50%",
                      float: "right",
                      marginBottom: "2px",
                    }}
                    placeholder="Device Brand"
                    defaultValue={filterData.brand?.name}
                    onChange={(event) => setBrand(event)}
                    labelInValue
                    showSearch
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider className="my-2" />
                        <div className="pb-3 px-2 w-100">
                          <Input
                            className="mr-2"
                            style={{ width: "80%" }}
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <Button
                            className="mb-0"
                            type="default"
                            icon={<PlusOutlined />}
                            onClick={addNewBrand}
                          >
                            Add
                          </Button>
                        </div>
                      </>
                    )}
                    options={brandList.map((item) => ({
                      label: item.display_name,
                      value: item.name,
                    }))}
                  ></Select>
                )}
              </div>
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Assigned Date </span>
                {!edit ? (
                  <span style={{ float: "right", color: "#2A2B2C" }}>
                    {filterData?.assigned_date
                      ? filterData.assigned_date
                      : "Not Available"}
                  </span>
                ) : (
                  <DatePicker
                    style={{
                      width: "50%",
                      float: "right",
                      marginBottom: "2px",
                    }}
                    placeholder="Select Date"
                    onChange={(event) => setAssignedDate(event)}
                    defaultValue={
                      filterData.assigned_date
                        ? moment(filterData?.assigned_date)
                        : null
                    }
                    disabled={ownerName === null ? true : false}
                    disabledDate={(currentDate) => currentDate > Date.now()}
                  />
                )}
              </div>
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Employee ID </span>
                {!edit ? (
                  <span style={{ float: "right", color: "#2A2B2C" }}>
                    {" "}
                    {ownerId ? ownerId : "Not Available"}
                  </span>
                ) : (
                  <Input
                    style={{
                      width: "50%",
                      float: "right",
                      marginBottom: "2px",
                    }}
                    placeholder="Employee ID"
                    readOnly={true}
                    disabled={true}
                    defaultValue={ownerId}
                  />
                )}
              </div>
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Owner </span>
                {!edit ? (
                  <span style={{ float: "right", color: "#2A2B2C" }}>
                    {ownerName ? ownerName : "Not Available"}
                  </span>
                ) : (
                  <Input
                    style={{
                      width: "50%",
                      float: "right",
                      marginBottom: "2px",
                    }}
                    placeholder="Employee Name"
                    readOnly={true}
                    disabled={true}
                    defaultValue={ownerName}
                  />
                )}
              </div>
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Condition </span>
                {!edit ? (
                  <span style={{ float: "right" }}>
                    {filterData.is_working ? (
                      <Tag
                        color="#EAFEEF"
                        style={{ color: "#136927", borderColor: "#28A745" }}
                      >
                        Working
                      </Tag>
                    ) : (
                      <Tag
                        color="#FFDCDC"
                        style={{ color: "#EE3636", borderColor: "#EF4444" }}
                      >
                        Not Working
                      </Tag>
                    )}
                  </span>
                ) : (
                  <Radio.Group
                    buttonStyle="solid"
                    style={{ borderRadius: "6px", float: "right" }}
                    defaultValue={
                      filterData.is_working ? "working" : "not_working"
                    }
                    onChange={(event) => {
                      if (event.target.value === "not_working") {
                        setDisableInUse(true);
                      } else {
                        setDisableInUse(false);
                      }
                      setCondition(event.target.value);
                    }}
                  >
                    <Radio.Button
                      value={"working"}
                      style={
                        (filterData.is_working &&
                          condition !== "not_working") ||
                        (condition === "working" && !filterData.is_working)
                          ? {
                              backgroundColor: "#D0F1E1",
                              color: "#0B6E40",
                              marginRight: "10px",
                            }
                          : null
                      }
                    >
                      <AiOutlineCheckCircle /> Working
                    </Radio.Button>
                    <Radio.Button
                      value={"not_working"}
                      style={
                        (!filterData.is_working && condition !== "working") ||
                        condition === "not_working"
                          ? {
                              backgroundColor: "#FFDCDC",
                              color: "#EE3636CC",
                              marginLeft: "10px",
                            }
                          : null
                      }
                    >
                      <AiOutlineCheckCircle /> Not Working
                    </Radio.Button>
                  </Radio.Group>
                )}
              </div>
              <div className="case-drawer">
                {edit ? (
                  <span style={{ color: "red", fontSize: 9 }}>*</span>
                ) : null}
                <span style={{ color: "#535558" }}> Status </span>
                {!edit ? (
                  <span style={{ float: "right" }}>
                    {filterData.in_use ? (
                      <Tag
                        color="#E6F7FF"
                        style={{ color: "#096DD9", borderColor: "#91D5FF" }}
                      >
                        In use
                      </Tag>
                    ) : (
                      <Tag
                        color="#FFEAAF"
                        style={{ color: "#C27A06", borderColor: "#FCD44D" }}
                      >
                        Not in use
                      </Tag>
                    )}
                  </span>
                ) : (
                  <Radio.Group
                    buttonStyle="solid"
                    style={{ borderRadius: "6px", float: "right" }}
                    defaultValue={filterData.in_use ? "in_use" : "not_in_use"}
                    onChange={(event) => setStatus(event.target.value)}
                  >
                    <Radio.Button
                      value={"in_use"}
                      style={
                        (filterData.in_use &&
                          status !== "not_in_use" &&
                          !disableInUse) ||
                        (status === "in_use" &&
                          !filterData.in_use &&
                          !disableInUse)
                          ? {
                              backgroundColor: "#C1E6FD",
                              color: "#096DD9",
                              marginRight: "10px",
                            }
                          : null
                      }
                      disabled={disableInUse}
                    >
                      <AiOutlineCheckCircle /> In Use
                    </Radio.Button>
                    <Radio.Button
                      value={"not_in_use"}
                      style={
                        (!filterData.in_use && status !== "in_use") ||
                        status === "not_in_use"
                          ? {
                              backgroundColor: "#FFEAAF",
                              color: "#C27A06",
                              marginLeft: "10px",
                            }
                          : disableInUse
                          ? {
                              backgroundColor: "#FFEAAF",
                              color: "#C27A06",
                              marginLeft: "10px",
                            }
                          : null
                      }
                    >
                      <AiOutlineCheckCircle /> Not in Use
                    </Radio.Button>
                  </Radio.Group>
                )}
              </div>
            </div>
            <br></br>
            <div
              className="case-drawer"
              style={{
                marginBottom: "20px",
              }}
            >
              <label
                style={{
                  marginTop: "10px",
                  color: "#000000",
                  fontSize: "16px",
                }}
              >
                Attachments
              </label>
              <br />
              <span
                style={{
                  color: "#2A2B2C",
                  fontSize: "12px",
                }}
              >
                Please find device images and serial number image here.
              </span>
            </div>
            <div className="asset-image-wrapper">
              <label
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  width: "100%",
                  color: "#535558",
                }}
              >
                Device Images{" "}
              </label>
              {!edit ? (
                <div
                  style={{
                    columnCount: "3",
                    columnGap: "5px",
                    display: "inline-block",
                    marginLeft: "10px",
                  }}
                >
                  {attachment.map((image, i) => {
                    return (
                      <div key={i}>
                        {console.log("image",image)}
                        <img
                          style={{
                            height: "80px",
                            marginBotttom: "10px",
                            width: "100%",
                            borderRadius: "6px",
                          }}
                          key={image.id}
                          src={image.file}
                          alt={image.file_name}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  {attachment.map((file, i) => {
                    return (
                      <div
                        key={file.id}
                        className="mb-2 ml-0"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "2px",
                          padding: "5px",
                        }}
                      >
                        <div style={{ color: "red", margin: "2px" }}>
                          {file.name ? file.name : file.file_name}
                        </div>
                        <div style={{ color: "red", margin: "2px" }}>
                          <CloseCircleOutlined
                            onClick={() => {
                              setAttachmentId(
                                attachmentId.filter((id) => id !== file.id)
                              );
                              if (attachment.length === 1) {
                                setAttachmentId([]);
                                setAttachment([]);
                              } else {
                                let newFileList = attachment.filter((f) => {
                                  if (f.id !== undefined)
                                    return f.id !== file.id;
                                  else return f.uid !== file.uid;
                                });
                                setAttachment([...newFileList]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                  {/* <label
                    htmlFor="assetUpload"
                    className="asset-avatar-uploader"
                  >
                    {" "}
                    <BiUpload
                      style={{
                        marginRight: "5px",
                        height: "26.67px",
                        width: "17.5px",
                      }}
                    />{" "}
                    Upload Images
                  </label> */}
                  <Upload
                    type="file"
                    className="asset-avatar-uploader"
                    multiple
                    accept=".png, .jpeg, .gif"
                    showUploadList={false}
                    // customRequest={({ onSuccess }) => { onSuccess('ok') }}
                    onChange={(f) => handleFileChange(f, "image")}
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    <Tooltip title="Upload File">
                      {" "}
                      <BiUpload
                        style={{
                          marginRight: "5px",
                          height: "26.67px",
                          width: "17.5px",
                          cursor: "pointer",
                        }}
                      />{" "}
                      Upload Images
                    </Tooltip>
                  </Upload>
                  <ul>
                    {bulkFile?.length > 0
                      ? bulkFile.map((file) => (
                          <li key={file.name}>
                            {file.name}
                            <CloseCircleOutlined
                              onClick={() => handleFileRemove(file.id)}
                              style={{ marginLeft: "2px" }}
                            />
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              )}
            </div>

            {(filterData.type?.display_name === "Laptop" && !disableSerial) ||
            (filterData.type?.display_name !== "Laptop" &&
              assetType === "laptop") ? (
              <div
                className="asset-image-wrapper"
                style={{
                  background: "white",
                  columnCount: "2",
                  columnGap: "10px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <span style={{ color: "#535558", marginLeft: "10px" }}>
                    {" "}
                    Serial Number Image
                  </span>
                  {!edit ? (
                    <div
                      style={{
                        columnCount: "3",
                        columnGap: "5px",
                        display: "inline-block",
                      }}
                    >
                      {serialAttachments.map((image, index) => {
                        return (
                          <div key={index}>
                            <img
                              style={{
                                height: "80px",
                                marginTop: "20px",
                                marginBotttom: "10px",
                                width: "100%",
                                borderRadius: "8px",
                              }}
                              key={image.id}
                              src={image.file}
                              alt={image.file_name}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div style={{ width: "100%", marginTop: "16px" }}>
                      {serialAttachments.map((file, i) => {
                        return (
                          <div
                            key={file.id}
                            className="mb-2 ml-0"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "2px",
                              padding: "5px",
                            }}
                          >
                            <div style={{ color: "red", margin: "2px" }}>
                              {file.name ? file.name : file.file_name}
                            </div>
                            <div style={{ color: "red", margin: "2px" }}>
                              <CloseCircleOutlined
                                onClick={() => {
                                  setAttachmentId(
                                    attachmentId.filter((id) => id !== file.id)
                                  );
                                  if (serialAttachments.length === 1) {
                                    setSerialAttachments([]);
                                  } else {
                                    let newFileList = attachment.filter((f) => {
                                      if (f.id !== undefined)
                                        return f.id !== file.id;
                                      else return f.uid !== file.uid;
                                    });
                                    setSerialAttachments([...newFileList]);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                      {/* <label
                        htmlFor="imageUpload"
                        className="asset-avatar-uploader"
                      >
                        {" "}
                        <BiUpload
                          style={{
                            marginRight: "5px",
                            height: "26.67px",
                            width: "17.5px",
                            cursor: "pointer",
                          }}
                        />{" "}
                        Upload Image
                      </label> */}
                      {/* <Upload
                        id="imageUpload"
                        style={{
                          opacity: "0",
                          position: "absolute",
                          zIndex: "-1",
                          cursor: "pointer",
                          borderRadius: "8px",
                        }}
                        type="file"
                        accept=".png, .jpeg, .gif"
                        onChange={(e) => {
                          handleFileChange(e.target.files, "serialImage");
                        }}
                        disabled={serialAttachments.length === 1}
                      />
                      {serialImage && serialImage.name ? (
                        <ul>
                          <li>{serialImage?.name}</li>
                        </ul>
                      ) : null} */}
                      <Upload
                        onChange={(f) => handleFileChange(f, "serialImage")}
                        className="asset-avatar-uploader"
                        multiple="false"
                        disabled={serialAttachments.length === 1}
                        showUploadList={false}
                        accept=".pdf,.docx,.jpeg,.png,.jpg,.heic"
                        // fileList={fileLists}
                        // listType="picture-card"
                        style={{
                          opacity: "0",
                          position: "absolute",
                          zIndex: "-1",
                          cursor: "pointer",
                          borderRadius: "8px",
                        }}
                      >
                        <Tooltip title="Upload File">
                          {" "}
                          <BiUpload
                            style={{
                              marginRight: "5px",
                              height: "26.67px",
                              width: "17.5px",
                              cursor: "pointer",
                            }}
                          />{" "}
                          Upload Image
                        </Tooltip>
                      </Upload>
                      <ul>
                        {serialImage?.length > 0
                          ? serialImage.map((file) => (
                              <li key={file.name}>
                                {file.name}
                                <CloseCircleOutlined
                                  onClick={() => handleFileRemove(file.id, "serial")}
                                  style={{ marginLeft: "2px" }}
                                />
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </Spin>
        </div>
      </Col>
      <Col span={11}>
        <div>
          <br />
          {activity.map((item, i) => {
            return (
              <div className="activity_card" key={i}>
                <BiHistory
                  style={{
                    overflow: "inherit",
                    marginRight: "5px",
                    marginBottom: "3px",
                    width: "32px",
                    height: "32px",
                    color: "#000000",
                    position: "absolute",
                  }}
                />
                <span style={{ fontSize: "12px", marginLeft: "50px" }}>
                  {" "}
                  {item.desc +
                    " on " +
                    moment(item.created).format("MM-DD-YYYY HH:mm")}
                </span>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default AssetDetails;
